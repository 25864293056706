import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
query {
  assets: allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
    edges {
      node {
        extension
        relativePath
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
          fixed(height: 630, width: 1200) {
            src
          }
        }
      }
    }
  }
}
`

export default function AssetFile(pathName) {
  const { assets } = useStaticQuery(query);
  if (pathName == null) return null;

  const file = assets.edges.find(
    asset => asset.node.relativePath === pathName
      || `/${asset.node.relativePath}` === pathName
  );

  if (file == null) return null;

  return file.node;
}
