import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FaTwitter, FaGithub, FaLinkedin, FaRssSquare } from "react-icons/fa"

export const query = graphql`
query {
  site {
    meta: siteMetadata {
      title
      footer {
        content
        legalLinks: legal_links {
          label
          url
        }
        hasSocial: has_social
        socialLinks: social_links {
          label
          url
          iconClass: icon_class
        }
      }
    }
  }
}
`

const Footer = () => {
  const {
    site: {
      meta: {
        title,
        footer
      }
    }
  } = useStaticQuery(query);

  return (
    <footer id="colophon" className="outer bg-gray-100 py-10">
      <div className="inner uppercase flex flex-wrap flex-col sm:flex-row mx-auto">
        <div className="mx-2 text-xs leading-6 tracking-wider text-center">
          <span className="">{title} © {new Date().getFullYear()}</span>
        </div>

        {footer.legalLinks && (
          <div className="flex-grow text-xs leading-6 tracking-wider text-center sm:text-left mt-2 sm:mt-0">
            {footer.legalLinks.map((link, linkIndex) => (
              <Link key={linkIndex} to={link.url} className="mx-1">
                {link.label}
              </Link>
            ))}
          </div>
        )}

        {footer.hasSocial && (
          <div className="text-center mt-4 sm:mt-0">
            {footer.socialLinks.map((link, linkIndex) => (
              <a key={linkIndex} href={link.url} target="_blank" rel="noreferrer noopener" className="mx-4 border-0">
                <React.Fragment>
                  {
                    {
                      'twitter': <FaTwitter className="inline" aria-hidden="true" />,
                      'github': <FaGithub className="inline" aria-hidden="true" />,
                      'linkedin': <FaLinkedin className="inline" aria-hidden="true" />,
                      'rss': <FaRssSquare className="inline" aria-hidden="true" />,
                    }[link.iconClass]
                  }
                  <span className="sr-only">{link.label}</span>
                </React.Fragment>
              </a>
            ))}
          </div>
        )}
      </div>
    </footer>
  );
}

export default Footer
