import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";

interface NewsletterBannerProps {
    showSubscribe: boolean,
}

export const NewsletterBanner = ({
    showSubscribe = true,
}: NewsletterBannerProps) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    hasSubscribe: has_subscribe
                }
            }
        }
    `)

    const hasSubscribe = showSubscribe && data.site.siteMetadata?.hasSubscribe

    if (!hasSubscribe) return null;

    const YOUR_FORM_ID = '2451870';
    const YOUR_FORM_URL = `https://app.convertkit.com/forms/${YOUR_FORM_ID}/subscriptions`;

    return (
        <div className="text-center p-4">
            <h3>
                Follow my journey
            </h3>
            <p className="mb-4">
                Get the latest updates in your inbox 📨
            </p>

            <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
            <form
                action={YOUR_FORM_URL}
                method="post"
                data-sv-form={YOUR_FORM_ID}
                data-uid="787a6f4e0f"
                data-format="inline"
                data-version="5"
                data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;redirect&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;https://www.manusobles.com/subscription-success&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:true},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}"
            >
                <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                <input
                    type="text"
                    aria-label="Your name"
                    name="fields[first_name]"
                    placeholder="Your name"
                    className="text-sm border border-solid border-border rounded bg-gray-100 focus:outline-none p-2 mx-2 mb-2 md:mb-0"
                />
                <input
                    type="email"
                    aria-label="Your email"
                    name="email_address"
                    placeholder="Your email address"
                    className="text-sm border border-solid border-border rounded bg-gray-100 focus:outline-none p-2 mx-2 my-2 md:my-0"
                    required
                />
                <button
                    type="submit"
                    className="button font-bold text-sm mx-2 mt-2 md:mt-0"
                >
                    <div className="formkit-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <span>Get updates</span>
                </button>
            </form>
            <p className="text-meta text-xs mt-4 mb-0">
                No spam. Unsubscribe as your heart desires.
            </p>
        </div>
    );
};
