import React from 'react';
import Helmet from 'react-helmet';

export default ({
    locale = "en_US",
    pageUrl = null,
    type = "website",
    title = null,
    description = null,
    image = null,
    imageAlt = null,
}) => (
    <Helmet>
        {locale && <meta name="og:locale" content={locale} />}
        {pageUrl && <meta property="og:url" content={pageUrl} />}
        {type && <meta property="og:type" content={type} />}
        {title && <meta property="og:title" content={title} />}
        {description && <meta property="og:description" content={description} />}
        {image && <meta property="og:image" content={image} />}
        {imageAlt && <meta property="og:image:alt" content={imageAlt} />}
    </Helmet>
);