import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "./Layout.css"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper min-h-screen flex flex-col" data-is-root-path={isRootPath}>
      <Header />

      <div className="outer flex-grow">
        <main className="inner mx-auto mb-8">{children}</main>
      </div>
      
      <Footer />
    </div>
  )
}

export default Layout
