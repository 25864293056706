import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
query {
  imageDefault: file(relativePath: {eq: "manusobles-icon.png"}) {
    childImageSharp {
      fixed(height: 512, width: 512) {
        src
      }
    }
  }
}
`

export default function DefaultImagePath() {
  const { imageDefault } = useStaticQuery(query)
  return imageDefault.childImageSharp.fixed.src
}
