/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { AssetFile, DefaultImagePath } from '../../util';
import Twitter from './Twitter';
import OpenGraph from './OpenGraph';

const SEO = ({
  title = null,
  description = null,
  imagePath = null,
  imageAlt = null,
  pathName = null,
  article = false,
  lang = "en",
  locale = "en_US",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            defaultImage
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const defaultTitle = site.siteMetadata?.title;
  const defaultDescription = site.siteMetadata.description;

  const baseUrl = site.siteMetadata?.siteUrl;
  const defaultImagePath = DefaultImagePath();
  const imageFile = AssetFile(imagePath);
  const imageUrl = imageFile == null ? `${baseUrl}${defaultImagePath}` : `${baseUrl}${imageFile.publicURL}`;

  const twitterUsername = site.siteMetadata.social.twitter;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    imageUrl: imageUrl,
    imageAlt: imageUrl == null ? null : imageAlt,
    url: `${baseUrl}${pathName || '/'}`,
    lang: lang,
    locale: locale
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `viewport`,
            content: `width=device-width, initialScale=1.0`,
          },
          {
            name: `description`,
            content: seo.description,
          },
        ]}
      />

      <OpenGraph
        pageUrl={seo.url}
        type={article ? 'article' : null}
        title={seo.title}
        description={seo.description}
        image={seo.imageUrl}
        imageAlt={seo.imageAlt}
        locale={seo.locale}
      />

      <Twitter
        cardType={article ? 'summary_large_image' : 'summary'}
        username={twitterUsername}
        title={seo.title}
        description={seo.description}
        image={seo.imageUrl}
        imageAlt={seo.imageAlt}
      />
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imagePath: PropTypes.string,
  imageAlt: PropTypes.string,
  pathName: PropTypes.string,
  article: PropTypes.bool,
  lang: PropTypes.string,
  locale: PropTypes.string,
};

export default SEO
