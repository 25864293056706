import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { VscMenu, VscChromeClose } from "react-icons/vsc"

export const query = graphql`
query {
  site {
    meta: siteMetadata {
      header {
        title
        hasNav: has_nav
        navLinks: nav_links {
          label
          url
        }
      }
    }
  }
}
`

function Header() {
    const {
        site: {
            meta: {
                header
            }
        }
    } = useStaticQuery(query);
    const [isExpanded, toggleExpansion] = useState(false)

    const currentPathName = useLocation().pathname;

    return (
        <>
            <div className="h-1 bg-accent" />
            <header className="outer py-5">
                <div className="inner flex mx-auto">
                    <p className="flex-none text-2xl font-serif font-bold mb-0">
                        <Link className="text-accent-black border-none focus:outline-none" to="/">
                            {header.title}
                        </Link>
                    </p>
                    {header.hasNav && (
                        <nav className="flex items-center justify-between flex-wrap ml-auto">
                            <div className="block sm:hidden ml-auto">
                                <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center text-accent-black hover:text-meta focus:outline-none px-3 py-1.5">
                                    {isExpanded
                                        ? <VscChromeClose className="w-5 h-5 text-primary">
                                            <span className="sr-only">Close menu</span>
                                        </VscChromeClose>
                                        : <VscMenu className="w-5 h-5 text-primary">
                                            <span className="sr-only">Open menu</span>
                                        </VscMenu>
                                    }
                                </button>
                            </div>
                            <div className={`${isExpanded ? `block` : `hidden`} w-full block flex-grow sm:flex sm:items-center sm:w-auto`}>
                                <div className="text-sm sm:flex-grow pt-3 sm:pt-0">
                                    {header.navLinks.map((link, linkIndex) => (
                                        <Link key={linkIndex} to={link.url} className={`block sm:inline-block text-right text-xs uppercase hover:text-meta leading-6 tracking-widest border-0 ml-5 mr-3 sm:mr-0 pt-2 sm:pt-0 focus:outline-none ${(currentPathName === link.url) ? "text-meta" : "text-primary"}`}>
                                            {link.label}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </nav>
                    )}
                </div>
            </header>
            <div className="h-px bg-border" />
        </>
    )
}

export default Header